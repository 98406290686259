
import { Link } from 'react-router-dom'
import { Button, Box } from '@chakra-ui/react'

const ScanButton = () => {
    return (
        <Box>
            <Link to="/scan">
                <Button
                    size="sm"
                    rounded="md"
                    color={["primary.500", "primary.500", "white", "white"]}
                    bg={["white", "white", "primary.500", "primary.500"]}
                    _hover={{
                        bg: ["primary.100", "primary.100", "primary.600", "primary.600"]
                    }}
                >
                    Scan Item
                </Button>
            </Link>
        </Box>
    );
};

export default ScanButton;

