// import logo from './logo.svg';
import './App.css';

import { Switch, Route } from 'react-router-dom'

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';

import Header from './components/Header'
import ScanButton from './components/ScanButton'

import Scan from './routes/Scan';
import Home from './routes/Home';
import FridgeContents from './routes/FridgeContents';
import ScanSuccess from './routes/ScanSuccess';


Amplify.configure(awsExports);

function App({ signOut, user }) {
  return (
    <div className="App">
      <Header />

      <ScanButton />

      <h1>Hello {user.username}</h1>
      <button onClick={signOut}>Sign Out</button>

      <Switch>
        <Route path='/scan' component={Scan} />
        <Route path='/scansuccess/:ean' component={ScanSuccess} />
        <Route path='/fridgecontents' component={FridgeContents} />
        <Route path='/' component={Home} exact />
      </Switch>
    </div >
  );
}

export default withAuthenticator(App);
// export default App;
