import React from "react";
import {
  Input,
  Container,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import DatePickerField from "../components/DatePickerField.js";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { DataStore } from "@aws-amplify/datastore";
import { Product } from "../models/index.js";

class ScanSuccess extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    this.state = {
      ean: "",
      bbfdate: today,
      title: "",
    };

    this.onDateChange = (date) => {
      console.log("old date", this.state.bbfdate);
      console.log("new date", date);
      this.setState({ bbfdate: date });
    };
  }

  componentDidMount() {
    this.setState({ ean: this.props.match.params.ean });
  }

  render() {
    return (
      <>
        <Container>
          <Formik
            initialValues={{ ean: this.state.ean, title: "", bbfdate: new Date() }}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {};
              if (!values.ean) {
                errors.ean = "Required";
              } else if (!/^[0-9]{8,13}$/i.test(values.ean)) {
                errors.ean = "Invalid EAN";
              }

              if (!values.title) {
                errors.title = "Required";
                //   } else if (
                //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.title)
                //   ) {
                //     errors.title = "Invalid title address";
              }

              if (!values.bbfdate) {
                errors.bbfdate = "Required";
                //   } else if (
                //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.bbfdate)
                //   ) {
                //     errors.bbfdate = "Invalid date";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await DataStore.save(
                new Product({
                  ean: values.ean,
                  title: values.title,
                  bbfDate: values.bbfdate.toISOString().split('T')[0]
                })
              );
              console.log("new product", values);
              setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Field type="text" name="ean">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.ean && form.touched.ean}
                    >
                      <FormLabel htmlFor="ean">EAN</FormLabel>
                      <Input {...field} id="ean" placeholder="ean" />
                      <FormErrorMessage>{form.errors.ean}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field type="text" name="title">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.title && form.touched.title}
                    >
                      <FormLabel htmlFor="title">title</FormLabel>
                      <Input {...field} id="title" placeholder="title" />
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field type="text" name="bbfdate">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.bbfdate && form.touched.bbfdate}
                    >
                      <FormLabel htmlFor="bbfdate">bbfdate</FormLabel>
                      <DatePickerField name="bbfdate" />
                      <FormErrorMessage>{form.errors.bbfdate}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  colorScheme="teal"
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </Container>
      </>
    );
  }
}

export default withRouter(ScanSuccess);
