import React, { Component } from "react";
import { DataStore, SortDirection } from "@aws-amplify/datastore";
import { Product } from "../models/index.js";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
} from "@chakra-ui/react";

class FridgeContents extends Component {
  constructor(props) {
    super(props);

    // TODO: add alert box if products are not synced
    this.state = {
      products: [],
      isProductsSynced: false,
    };
  }

  componentDidMount() {
    const getProducts = async () => {
      DataStore.observeQuery(Product, null, {
        sort: (s) => s.bbfDate(SortDirection.ASCENDING),
      }).subscribe((snapshot) => {
        const { items, isSynced } = snapshot;
        console.log(
          `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
        );
        console.log("items", items);
        this.setState({
          products: items,
          isProductsSynced: isSynced,
        });
        console.log("state.products", this.state.products);
      });
    };

    getProducts();
  }

  render() {
    return (
      <Container>
        <Accordion>
          {this.state.products.map(function (p) {
            return (
              <AccordionItem key={p.id}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {p.title}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {p.ean} <br />
                  {p.bbfDate}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Container>
    );
  }
}

export default FridgeContents;
