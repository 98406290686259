import React from "react";
import { Heading } from "@chakra-ui/react";

function Home() {
    return (
      <div className="Home">
        <Heading size='lg' fontSize='50px'>
          This is the home page.
        </Heading>
      </div>
    );
  }
  
  export default Home;