import React from "react";
import { Heading } from "@chakra-ui/react";

import Html5QrcodePlugin from "../components/Html5QrcodePlugin";
import { withRouter } from "react-router-dom";

class Scan extends React.Component {
  constructor(props) {
    super(props);

    // let history = useHistory();
    const { history } = this.props;
    this.onNewScanResult = this.onNewScanResult.bind(this);
  }

  onNewScanResult(decodedText, decodedResult) {
    console.log("decodedText", decodedText);
    console.log("decodedResult", decodedResult);
    this.props.history.push("/scanSuccess/" + decodedText);
    console.log("should have redirected");
  }

  // onScanError(errorMessage, error) {
  //   console.log("errorMessage", errorMessage);
  //   console.log("scan error", error);
  // }

  render() {
    return (
      <div className="Scan">
        <Heading size="lg" fontSize="50px">
          This is the scan page.
        </Heading>

        <Html5QrcodePlugin
          fps={10}
          qrbox={500}
          disableFlip={true}
          qrCodeSuccessCallback={this.onNewScanResult}
          // qrCodeErrorCallback={this.onScanError}
        />
      </div>
    );
  }
}

export default withRouter(Scan);